import styled from "styled-components";
import { foundations } from "@r114dev/rds";

const { getColor, typography } = foundations;

const ComponentCardStyledComponents = {
  Container: styled.article`
    display: flex;
    flex-direction: column;
    gap: 24px;

    cursor: pointer;

    picture {
      transform: translateY(0);
      transition: 0.2s linear;
    }
    &:hover {
      picture {
        transform: translateY(-2%);
        box-shadow: rgba(0, 0, 33, 0.05) 0px 16px 22.4px 4.8px,
          rgba(0, 0, 33, 0.03) 0px 3.2px 16px 0px,
          rgba(0, 0, 33, 0.05) 0px 0px 1px 0px;
      }
    }
  `,
  Picture: styled.picture`
    display: flex;
    img {
      width: 100%;
      height: auto;
    }
  `,
  Description: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    h3 {
      ${getColor("color", "black", "white")}
      ${typography.heading04B}
    }
    p {
      margin: 0;
      ${getColor("color", "gray600", "gray400")};
      ${typography.body01M}
    }
  `,
};

export default ComponentCardStyledComponents;
