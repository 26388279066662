import styled from "styled-components";
import { foundations } from "@r114dev/rds";

const { getColor, typography } = foundations;

const HorizonTitleStyledComponents = {
  Container: styled.div`
    width: 100%;
    margin-bottom: 26px;
  `,

  Title: styled.div`
    width: 100%;

    display: flex;
    gap: 8px;

    margin: 0 0 4px;

    ${typography.heading04B}
    ${getColor("color", "black")}
  `,
  Subtitle: styled.span`
    ${typography.heading04M}
    ${getColor("color", "gray400")}
  `,
  Hr: styled.hr`
    width: 100%;
    height: 1px;

    margin: 0;

    border: none;
    border-bottom: 1px solid;

    ${getColor("border-color", "gray400")};
  `,
};

export default HorizonTitleStyledComponents;
