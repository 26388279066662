import styled from "styled-components";
import { foundations } from "@r114dev/rds";

const { getColor, typography } = foundations;

const BulletListStyledComponents = {
  Container: styled.ul`
    margin: 0;
    padding: 0;

    list-style: none;

    li {
      position: relative;
      padding-left: 23px;

      ${getColor("color", "gray600")};
      ${typography.body01M}

      &::before {
        content: "";
        
        width: 3px;
        height: 3px;
        
        position: absolute;
        left: 10px;
        top: 11px;

        border-radius: 3px;
        ${getColor("background-color", "gray600")};
        transform: translateY(-50%);
      }
    }
  `,
};

export default BulletListStyledComponents;
