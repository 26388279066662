import styled from "styled-components";
import { foundations } from "@r114dev/rds";

const { colors, getColor, typography } = foundations;

const CardStyledComponents = {
  Container: styled.article`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,

  Picture: styled.picture`
    display: flex;
    img {
      width: 100%;
      height: auto;
    }
  `,

  Description: styled.div<{ theme: { titleColor?: keyof typeof colors } }>`
    display: flex;
    flex-direction: column;
    gap: 8px;

    h3 {
      ${({ theme: { titleColor } }) =>
        getColor("color", titleColor || "gray900", titleColor || "gray100")};
      ${typography.heading04B}
    }
    p {
      margin: 0;
      ${getColor("color", "gray600", "gray400")};
      ${typography.body01M}
    }
  `,
};

export default CardStyledComponents;
