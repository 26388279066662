export const headerHeight = 80;
export const mobileHeaderHeight = 64;
export const sidebarWidth = 240;

export const pcViewWidth = 1920;
export const tabletViewWidth = 768;
export const mobileViewWidth = 360;

export const tabletBreakPoint = 1440;
export const mobileBreakPoint = 767;

export const transitionDelay = 0.4;

export const sideMargin = 20;
