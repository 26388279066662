import { FC } from "react";

import { HorizonTitleProps } from "./types";
import HorizonTitleStyledComponents from "./styled";

const { Container, Title, Subtitle, Hr } = HorizonTitleStyledComponents;

const HorizonHeading: FC<HorizonTitleProps> = ({ title, subtitle }) => {
  return (
    <Container>
      <Title>
        {title}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Title>
      <Hr />
    </Container>
  );
};

export default HorizonHeading;
