import styled from "styled-components";
import { foundations } from "@r114dev/rds";

const { getColor, typography } = foundations;

const OrderListStyledComponents = {
  Container: styled.ol`
    margin: 0;
    padding: 0;

    list-style-position: inside;

    li {
      ${getColor("color", "gray600")};
      ${typography.body01M}
    }
  `,
};

export default OrderListStyledComponents;
