import { FC } from "react";

import { CardProps } from "./types";
import CardStyledComponents from "./styled";
import Image from "next/image";

const { Container, Picture, Description } = CardStyledComponents;

const Card: FC<CardProps> = ({
  imageUrl,
  title,
  description,
  titleColor,
  imageWidth,
  imageHeight,
}) => {
  return (
    <Container>
      <Picture>
        <Image
          src={imageUrl}
          alt={title}
          width={imageWidth}
          height={imageHeight}
        />
      </Picture>
      <Description theme={{ titleColor }}>
        <h3>{title}</h3>
        <p>{description}</p>
      </Description>
    </Container>
  );
};

export default Card;
