import { FC } from "react";

import Link from "next/link";
import Image from "next/image";

import { ComponentCardProps } from "./types";
import ComponentCardStyledComponents from "./styled";

const { Container, Picture, Description } = ComponentCardStyledComponents;

const ComponentCard: FC<ComponentCardProps> = ({
  imageUrl,
  title,
  path,
  description,
}) => {
  return (
    <Link href={path}>
      <Container>
        <Picture>
          <Image src={imageUrl} alt={title} width={344} height={240} />
        </Picture>
        <Description>
          <h3>{title}</h3>
          <p>{description}</p>
        </Description>
      </Container>
    </Link>
  );
};

export default ComponentCard;
