import styled from "styled-components";
import { foundations } from "@r114dev/rds";

const { getColor, typography } = foundations;

const UsageStyledComponents = {
  Container: styled.div`
    p {
      margin: 0 0 8px 0;
      ${typography.heading04B}

      &:nth-of-type(1) {
        ${getColor("color", "green500")}
      }
      &:nth-of-type(2) {
        ${getColor("color", "red500")}
      }
    }

    ul {
      &:first-of-type {
        margin-bottom: 80px;
      }
    }
  `,
};

export default UsageStyledComponents;
