import { FC } from "react";
import Image from "next/image";

import { Column, TitleTableProps } from "./types";
import TitleTableStyledComponents from "./styled";

const { Container, TableContainer, Text, Picture } = TitleTableStyledComponents;

const TitleTable: FC<TitleTableProps> = ({ config, title, caption }) => {
  const renderColumn = (column: Column) => {
    switch (column.type) {
      case "image":
        return (
          <Picture>
            <Image
              src={column.value}
              alt="column"
              width={column.size?.[0] || 100}
              height={column.size?.[1] || 40}
            />
          </Picture>
        );

      case "text":
        return <Text>{column.value}</Text>;
    }
  };
  return (
    <Container>
      <TableContainer>
        <caption>{caption}</caption>
        <colgroup>
          {title.map((data) => {
            return <col key={data.key} width={data.width} />;
          })}
        </colgroup>
        <thead>
          <tr>
            {title.map((data) => {
              return <th key={data.key}>{data.name}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {config.map((row, rowIndex) => {
            return (
              <tr key={rowIndex}>
                {row.map((column, columnIndex) => {
                  if (columnIndex === 0) {
                    return <th key={columnIndex}>{renderColumn(column)}</th>;
                  }
                  return <td key={columnIndex}>{renderColumn(column)}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </TableContainer>
    </Container>
  );
};

export default TitleTable;
