import styled from "styled-components";
import { foundations } from "@r114dev/rds";

import { mobileBreakPoint, tabletBreakPoint } from "../../constants/size";

const { getColor, typography } = foundations;

export const PostProviderStyledComponents = {
  H1: styled.h1`
    margin: 0 0 32px 0;
    ${typography.display03BL}
  `,
  H2: styled.h2`
    margin: 0 0 12px 0;
    ${typography.heading02B}
  `,
  H3: styled.h3`
    margin: 0 0 24px 0;
    ${typography.heading03B}
  `,
  H4: styled.h4`
    margin: 0 0 12px 0;
    ${typography.heading04B}
  `,
  H5: styled.h5`
    margin: 0 0 12px 0;
    ${typography.body01B}
  `,

  Paragraph: styled.p`
    margin: 0 0 40px 0;

    ${getColor("color", "gray600", "gray400")};
    ${typography.body01M}
    white-space: pre-line;
  `,

  Hr: styled.hr<{ theme: { marginBottom?: number } }>`
    width: 100%;
    height: 1px;

    margin: 0 0 ${({ theme: { marginBottom } }) => marginBottom || 80}px;
    border: none;
    ${getColor("background", "gray200", "gray800")};
  `,

  Space: styled.div<{
    theme: { desktop: number; tablet: number; mobile: number };
  }>`
    height: ${({ theme: { desktop } }) => desktop}px;

    @media (max-width: ${tabletBreakPoint}px) {
      height: ${({ theme: { tablet } }) => tablet}px;
    }
    @media (max-width: ${mobileBreakPoint}px) {
      height: ${({ theme: { mobile } }) => mobile}px;
    }
  `,
};
