import { FC } from "react";

import { UsageCardsProps } from "./types";
import UsageCardsStyledComponents from "./styled";
import UsageCard from "../UsageCard/UsageCard";

const { Container } = UsageCardsStyledComponents;

const UsageCards: FC<UsageCardsProps> = ({ items }) => {
  return (
    <Container>
      {items.map((props, index) => {
        return <UsageCard key={index} {...props} />;
      })}
    </Container>
  );
};

export default UsageCards;
