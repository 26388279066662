import { FC } from "react";

import { ComponentCardsProps } from "./types";
import ComponentCardsStyledComponents from "./styled";

import ComponentCard from "../ComponentCard";

const { Container } = ComponentCardsStyledComponents;

const ComponentCards: FC<ComponentCardsProps> = ({ items }) => {
  return (
    <Container>
      {items.map((item, index) => (
        <ComponentCard key={index} {...item} />
      ))}
    </Container>
  );
};

export default ComponentCards;
