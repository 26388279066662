import { FC } from "react";

import { BulletListProps } from "./types";
import BulletListStyledComponents from "./styled";

const { Container } = BulletListStyledComponents;

const BulletList: FC<BulletListProps> = ({ items }) => {
  return (
    <Container>
      {items.map((value, index) => {
        return <li key={index}>{value}</li>;
      })}
    </Container>
  );
};

export default BulletList;
