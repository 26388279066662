import styled from "styled-components";
import { foundations } from "@r114dev/rds";

const { getColor, typography } = foundations;

import {
  mobileBreakPoint,
  sideMargin,
  tabletBreakPoint,
} from "../../constants/size";

const TableStyledComponents = {
  Container: styled.div`
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  `,

  TableContainer: styled.table`
    width: 100%;
    border-collapse: collapse;

    caption {
      width: 1px;
      height: 1px;

      overflow: hidden;
      position: absolute;
      margin: -1px;
      clip: rect(0 0 0 0);
    }

    tr {
      &:first-of-type {
        border-top: 1px solid;
        ${getColor("border-top-color", "gray200", "gray800")};
      }

      border-bottom: 1px solid;
      ${getColor("border-bottom-color", "gray200", "gray800")};

      th {
        width: 20%;
        padding: 54px 16px 54px 0;
        text-align: left;
        white-space: nowrap;
      }

      td {
        padding: 54px 0;
        overflow-x: hidden;
        word-break: keep-all;
        ${getColor("color", "gray700", "gray300")};
      }
    }

    @media (max-width: ${tabletBreakPoint}px) {
      tr {
        th {
          padding: 48px 16px 48px 0;
        }
        td {
          padding: 48px 0;
        }
      }
    }
    @media (max-width: ${mobileBreakPoint}px) {
      tr {
        th {
          width: 30%;
          padding: 27px 24px 27px 0;
        }
        td {
          padding: 27px 0;
        }
      }
    }
  `,

  Text: styled.span`
    ${typography.body01M};
  `,

  Picture: styled.picture`
    display: flex;

    img {
      max-width: calc(100% - ${sideMargin}px);
      object-fit: contain;
    }
  `,
};

export default TableStyledComponents;
