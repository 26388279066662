import styled from "styled-components";
import { foundations } from "@r114dev/rds";

const { getColor, typography } = foundations;

import {
  mobileBreakPoint,
  sideMargin,
  tabletBreakPoint,
} from "../../constants/size";

const TitleTableStyledComponents = {
  Container: styled.div`
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  `,

  TableContainer: styled.table`
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    caption {
      width: 1px;
      height: 1px;

      overflow: hidden;
      position: absolute;
      margin: -1px;
      clip: rect(0 0 0 0);
    }

    thead {
      tr {
        border-bottom: 1px solid;
        ${getColor("border-bottom-color", "gray200", "gray800")};
        th {
          padding-bottom: 12px;

          text-align: left;
          white-space: nowrap;
          ${typography.body01B}
          ${getColor("color", "gray600", "gray400")};
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid;
        ${getColor("border-bottom-color", "gray200", "gray800")};

        th {
          padding: 37px 0;

          text-align: left;
          white-space: nowrap;
        }

        td {
          overflow-x: hidden;
          padding: 38px 0;

          ${getColor("color", "gray600", "gray400")};
          word-break: keep-all;

          span {
            color: inherit;
          }
        }
      }
    }

    @media (max-width: ${tabletBreakPoint}px) {
      tr {
        th {
          padding: 48px 16px 48px 0;
        }
        td {
          padding: 48px 0;
        }
      }
    }
    @media (max-width: ${mobileBreakPoint}px) {
      tr {
        th {
          width: 30%;
          padding: 27px 24px 27px 0;
        }
        td {
          padding: 27px 0;
        }
      }
    }
  `,

  Text: styled.span`
    ${typography.body01M};
  `,

  Picture: styled.picture`
    display: flex;

    img {
      max-width: calc(100% - ${sideMargin}px);
      object-fit: contain;
    }
  `,
};

export default TitleTableStyledComponents;
