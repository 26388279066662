import { FC } from "react";

import { foundations } from "@r114dev/rds";

import { UsageCardProps } from "./types";
import Card from "../Card";

const { colors } = foundations;

const UsageCard: FC<UsageCardProps> = (props) => {
  let title = "Title",
    color: keyof typeof colors;
  switch (props.type) {
    case "do":
      title = "Do";
      color = "green500";
      break;
    case "not":
      title = "Don't";
      color = "red500";
      break;
  }

  return <Card {...props} title={title} titleColor={color} />;
};

export default UsageCard;
