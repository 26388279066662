import { FC } from "react";
import { UsageProps } from "./types";
import UsageStyledComponents from "./styled";
import BulletList from "../BulletList";

const { Container } = UsageStyledComponents;

const Usage: FC<UsageProps> = ({ items, items2 }) => {
  return (
    <Container>
      <p>Do</p>
      <BulletList items={items} />
      <p>Don’t</p>
      <BulletList items={items2} />
    </Container>
  );
};

export default Usage;
