"use client";

import { FC } from "react";

import { BlockImageProps } from "./types";
import BlockImageStyledComponents from "./styled";
import Image from "next/image";

const { Placeholder, Picture } = BlockImageStyledComponents;

const BlockImage: FC<BlockImageProps> = (props) => {
  return props.src ? (
    <Picture>
      <Image
        {...props}
        alt={props.alt}
        width={props.width}
        height={props.height}
      />
    </Picture>
  ) : (
    <Placeholder>이미지를 추가하세요.</Placeholder>
  );
};

export default BlockImage;
