import styled from "styled-components";
import { mobileBreakPoint, tabletBreakPoint } from "../../constants/size";

const UsageCardsStyledComponents = {
  Container: styled.section`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 80px 24px;

    &:before {
      padding: 0;
    }

    @media (max-width: ${tabletBreakPoint}px) {
      gap: 64px 20px;
    }
    @media (max-width: ${mobileBreakPoint}px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 64px;
    }
  `,
};

export default UsageCardsStyledComponents;
