import { FC } from "react";

import { OrderListProps } from "./types";
import OrderListStyledComponents from "./styled";

const { Container } = OrderListStyledComponents;

const OrderList: FC<OrderListProps> = ({ items }) => {
  return (
    <Container>
      {items.map((value, index) => {
        return <li key={index}>{value}</li>;
      })}
    </Container>
  );
};

export default OrderList;
