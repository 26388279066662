import { FC } from "react";

import { CardsProps } from "./types";
import CardsStyledComponents from "./styled";
import Card from "../Card";

const { Container } = CardsStyledComponents;

const Cards: FC<CardsProps> = ({ items }) => {
  return (
    <Container>
      {items.map((props, index) => {
        return <Card key={index} {...props} />;
      })}
    </Container>
  );
};

export default Cards;
