import styled from "styled-components";
import { foundations } from "@r114dev/rds";

const { getColor, typography } = foundations;

const BlockImageStyledComponents = {
  Placeholder: styled.div`
    width: 100%;
    height: 300px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    border: 1px dashed;
    ${getColor("border-color", "gray400", "gray600")};
    border-radius: 16px;

    ${getColor("color", "gray400", "gray600")};

    ${typography.body01M}
  `,
  Picture: styled.picture`
    display: flex;
    img {
      width: 100%;
      height: auto;
    }
  `,
};

export default BlockImageStyledComponents;
