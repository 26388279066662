"use client";

import { FC, createContext } from "react";

import { develop } from "@r114dev/rds";

import { PostProviderProps } from "./types";
import { PostProviderStyledComponents } from "./styled";

import HorizonHeading from "../HorizonHeading";
import BulletList from "../BulletList";
import OrderList from "../OrderList";
import Table from "../Table";
import TitleTable from "../TitleTable";

import BlockImage from "../BlockImage";
import Cards from "../Cards";
import Usage from "../Usage";
import UsageCards from "../UsageCards";
import ComponentCards from "../ComponentCards";

const { APIDocs, Playground, Editor } = develop;

const { H1, H2, H3, H4, H5, Paragraph, Hr, Space } =
  PostProviderStyledComponents;

const HRComponent: FC<{ marginBottom: number }> = ({ marginBottom }) => (
  <Hr theme={{ marginBottom }} />
);

const SpaceComponent: FC<{
  desktop: number;
  tablet: number;
  mobile: number;
}> = ({ desktop, tablet, mobile }) => (
  <Space theme={{ desktop, tablet, mobile }} />
);

const commonComponentValues = [
  {
    title: "Heading 1",
    component: <H1>텍스트를 입력해 주세요.</H1>,
  },
  {
    title: "Heading 2",
    component: <H2>텍스트를 입력해 주세요.</H2>,
  },
  {
    title: "Heading 3",
    component: <H3>텍스트를 입력해 주세요.</H3>,
  },
  {
    title: "Heading 4",
    component: <H4>텍스트를 입력해 주세요.</H4>,
  },
  {
    title: "Heading 5",
    component: <H5>텍스트를 입력해 주세요.</H5>,
  },
  {
    title: "Horizon Heading",
    component: <HorizonHeading title="텍스트를 입력해 주세요." />,
  },
  {
    title: "Horizon Heading - Subtitle",
    component: <HorizonHeading title="텍스트를 입력해 주세요." subtitle="" />,
  },
  {
    title: "Paragraph",
    component: <Paragraph>텍스트를 입력해 주세요.</Paragraph>,
    isDefault: true,
  },
  {
    title: "Horizon",
    component: <HRComponent marginBottom={80} />,
    hasNoChildren: true,
  },
  {
    title: "Space",
    component: <SpaceComponent desktop={60} tablet={40} mobile={20} />,
    hasNoChildren: true,
  },
  {
    title: "Block Image",
    component: <BlockImage src="" alt="" width={1080} height={480} />,
    hasNoChildren: true,
  },
  {
    title: "Bullet List",
    component: <BulletList items={["텍스트를 입력해 주세요."]} />,
  },
  {
    title: "Order List",
    component: <OrderList items={["텍스트를 입력해 주세요."]} />,
  },
  {
    title: "Table",
    component: (
      <Table
        caption="테이블 설명을 작성해주세요."
        config={[[{ type: "text", value: "Table" }]]}
      />
    ),
  },
  {
    title: "TitleTable",
    component: (
      <TitleTable
        caption="테이블 설명을 작성해주세요."
        title={[{ key: 0, name: "Style", width: "40%" }]}
        config={[[{ type: "text", value: "Table" }]]}
      />
    ),
  },
  {
    title: "Cards",
    component: (
      <Cards
        items={[
          {
            imageUrl: "/images/sample/Card.png",
            imageWidth: 344,
            imageHeight: 240,
            title: "Title",
            description: "설명을 입력해 주세요.",
          },
          {
            imageUrl: "/images/sample/Card.png",
            imageWidth: 344,
            imageHeight: 240,
            title: "Title",
            description: "설명을 입력해 주세요.",
          },
          {
            imageUrl: "/images/sample/Card.png",
            imageWidth: 344,
            imageHeight: 240,
            title: "Title",
            description: "설명을 입력해 주세요.",
          },
        ]}
      />
    ),
  },
  {
    title: "Usage",
    component: (
      <Usage
        items={["텍스트를 입력해 주세요."]}
        items2={["텍스트를 입력해 주세요."]}
      />
    ),
  },
  {
    title: "Usage Cards",
    component: (
      <UsageCards
        items={[
          {
            type: "do",
            imageUrl: "/images/sample/Do.png",
            imageWidth: 528,
            imageHeight: 320,
            description: "설명을 입력해 주세요.",
          },
          {
            type: "not",
            imageUrl: "/images/sample/Don't.png",
            imageWidth: 528,
            imageHeight: 320,
            description: "설명을 입력해 주세요.",
          },
          {
            type: "do",
            imageUrl: "/images/sample/Do.png",
            imageWidth: 528,
            imageHeight: 320,
            description: "설명을 입력해 주세요.",
          },
          {
            type: "not",
            imageUrl: "/images/sample/Don't.png",
            imageWidth: 528,
            imageHeight: 320,
            description: "설명을 입력해 주세요.",
          },
        ]}
      />
    ),
  },
  {
    title: "Component Card",
    component: (
      <ComponentCards
        items={[
          {
            imageUrl: "/images/sample/ComponentCard.png",
            title: "Title",
            path: "",
          },
          {
            imageUrl: "/images/sample/ComponentCard.png",
            title: "Title",
            path: "",
          },
          {
            imageUrl: "/images/sample/ComponentCard.png",
            title: "Title",
            path: "",
          },
        ]}
      />
    ),
  },
  {
    title: "Playground",
    component: <Playground demo="" displayName="" />,
  },
  {
    title: "API Docs",
    component: (
      <APIDocs rows={[{ props: "", description: "", type: "", default: "" }]} />
    ),
  },
  {
    title: "Editor",
    component: <Editor value="" onChange={() => ""} isReadOnly />,
  },
];

export const PostContext = createContext(commonComponentValues);

const PostProvider: FC<PostProviderProps> = ({ children }) => {
  return (
    <PostContext.Provider value={commonComponentValues}>
      {children}
    </PostContext.Provider>
  );
};

export default PostProvider;
